import React, { FunctionComponent } from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronLeft,
  faChevronRight,
  faExclamation,
  faGamepad,
  faHeartbeat,
  faPlay,
  faShieldAlt,
  faSmileBeam,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"
import tedx from "../images/tedx.png"

import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import "swiper/css"

import Layout from "../components/Layout"
import SEO from "../components/SEO"
import { Interview, SuccessStory } from "../types"
import OnScreen from "../components/OnScreen"
import Timeline, { TimelineEvent } from "../components/Timeline"
import StartButton from "../components/StartButton"
import Button from "../components/Button"
import TypedText from "../components/TypedText"

interface Props {
  data: any
}

const About: FunctionComponent<Props> = ({ data }) => {
  const newData = data.allContentfulAbout.edges[0].node

  console.log(newData)

  const interviews = data.allContentfulInterview.edges.map(
    (elem: any) => elem.node
  ) as Interview[]
  const stories = data.allContentfulSuccessStory.edges.map(
    (elem: any) => elem.node
  ) as SuccessStory[]

  const events: TimelineEvent[] = [
    {
      color: "blue",
      direction: "NEUTRAL",
      icon: faGamepad,
      image: (
        <StaticImage
          alt="Game Controller"
          placeholder="blurred"
          src="../images/about-timeline-1-v2.png"
        />
      ),
      // message:
      //   "Pasadena Ca - Will is born Rocky Clay Thomas Wooten Moore (brother to Puff Sunpath Moore) to two hippies. He's bright eyed, bushy tailed, and ready to take on the world!",
      message: (
        <div className="text-link">
          {renderRichText(newData.stepOne, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Game Starts - Will is Born",
    },
    {
      color: "coral",
      direction: "DOWN",
      icon: faShieldAlt,
      image: (
        <StaticImage
          alt="Game Shield"
          placeholder="blurred"
          src="../images/about-timeline-2-v2.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepTwo, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Shields Weaken - Early Childhood",
    },
    {
      color: "coral",
      direction: "DOWN",
      icon: faExclamation,
      image: (
        <StaticImage
          alt="Exclamation Point"
          placeholder="blurred"
          src="../images/about-timeline-3.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepThree, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Critical Life Alert - Adolescence",
    },
    {
      color: "blue",
      direction: "NEUTRAL",
      icon: faHeartbeat,
      image: (
        <StaticImage
          alt="Game Heart"
          placeholder="blurred"
          src="../images/about-timeline-4.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepFour, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Extra Life - Rock Bottom Bounce",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faPlay,
      image: (
        <StaticImage
          alt="Game Play Button"
          placeholder="blurred"
          src="../images/about-timeline-5.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepFive, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Learn How To Play - Young Adulthood",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faThumbsUp,
      image: (
        <StaticImage
          alt="Game Thumbs Up"
          placeholder="blurred"
          src="../images/about-timeline-6.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepSix, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Obtain Upgrades - Adulthood",
    },
    {
      color: "teal",
      direction: "UP",
      icon: faSmileBeam,
      image: (
        <StaticImage
          alt="Game Smiley Face"
          placeholder="blurred"
          src="../images/about-timeline-7.png"
        />
      ),
      message: (
        <div className="text-link">
          {renderRichText(newData.stepSeven, {
            renderMark: {
              [MARKS.BOLD]: text => <span className="font-bold">{text}</span>,
              [MARKS.ITALIC]: text => <em>{text}</em>,
            },
            renderNode: {
              [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
              [BLOCKS.HEADING_1]: (node, children) => (
                <h1 className="font-Orbitron text-2xl">{children}</h1>
              ),
              [BLOCKS.HEADING_2]: (node, children) => (
                <h2 className="font-Orbitron text-xl">{children}</h2>
              ),
              [BLOCKS.HEADING_3]: (node, children) => (
                <h3 className="font-Orbitron text-lg">{children}</h3>
              ),
              [BLOCKS.EMBEDDED_ASSET]: node => {
                if (!node.data.target) {
                  return
                }
                // console.log("Imagess",node.data.target.gatsbyImageData)
                const { gatsbyImageData } = node.data.target
                if (!gatsbyImageData) {
                  // asset is not an image
                  return null
                }
                return (
                  <figure className="flex flex-wrap justify-center items-center">
                    <GatsbyImage image={gatsbyImageData} alt="Blog Image" />
                  </figure>
                )
              },
            },
          })}
        </div>
      ),
      title: "Master The Game - Today",
    },
  ]

  function getTestimonials() {
    const order = ["Harrison Loew", "Mitzi Perdue", "Mark Hansen"]
    const testimonials = []

    for (let i = 0; i < order.length; i += 1) {
      for (let j = 0; j < stories.length; j += 1) {
        if (order[i] === stories[j].name) {
          const elem = stories[j]

          testimonials.push(
            <OnScreen
              className="bg-gray p-4 rounded-md shadow-md"
              classToAdd="slide-in-left"
              key={elem.id}
            >
              <div className="flex items-center mb-8">
                <div className="h-28 w-28">
                  <GatsbyImage
                    alt={elem.title}
                    className="rounded-full filter drop-shadow-lg"
                    image={elem.coverImage.gatsbyImageData}
                  />
                </div>

                <div className="flex-1 ml-8">
                  <p className="font-Orbitron mb-1 text-2xl">{elem.name}</p>

                  <p>{elem.title}</p>
                </div>
              </div>

              <p className="pb-4">{elem.testimonial.testimonial}</p>
            </OnScreen>
          )
        }
      }
    }

    return testimonials
  }

  return (
    <Layout absoluteHeader>
      <SEO
        description="Will Moore is a Serial Entrepreneur, Gamification, Habits, and Happiness Expert. He helps people to gamify their lives by adding success habits in life"
        title="Why Will Moore | Moore Momentum"
        link="https://mooremomentum.com/why-will-moore/"
        image="https://mooremomentum.com/static/e6cd45870f06c92153834a549c315b18/f615c/family.webp"
      />

      <div className="overflow-hidden">
        <div className="primary-gradient-bg pb-12 md:pb-28 pt-32 xl:pt-40">
          <div className="max-w-4xl mx-auto px-4 text-center text-white">
            <div className="mt-8 lg:mt-0">
              <OnScreen classToAdd="scale-in-ver-center">
                <h1 className="font-Orbitron mb-4 text-shadow text-4xl lg:text-5xl lg:leading-tight">
                  {newData.headline}{" "}
                </h1>

                <h2 className="font-Orbitron mb-2">{newData.subHeading}</h2>
              </OnScreen>
            </div>
          </div>
        </div>
        <div className="max-w-full mb-16 px-4 md:container md:mx-auto lg:mb-32">
          <h2 className="font-Orbitron mb-16 text-center text-3xl sm:mb-8 lg:text-4xl">
            {newData.stepsHeading}
          </h2>

          <Timeline events={events} />
        </div>

        <div className="max-w-full mt-12 mx-auto px-4 lg:container">
          <div className="gap-4 grid grid-cols-1 items-center lg:grid-cols-2">
            <div className="mt-4 order-2 lg:mt-0 lg:order-1">
              <OnScreen classToAdd="slide-in-left">
                <div className="space-y-4">
                  <div className="text-link">
                    {renderRichText(newData.firstSection, {
                      renderMark: {
                        [MARKS.BOLD]: text => (
                          <span className="font-bold">{text}</span>
                        ),
                        [MARKS.ITALIC]: text => <em>{text}</em>,
                      },
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p>{children}</p>
                        ),
                        [BLOCKS.HEADING_1]: (node, children) => (
                          <h1 className="font-Orbitron text-4xl">{children}</h1>
                        ),
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <h2 className="font-Orbitron text-3xl">{children}</h2>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 className="font-Orbitron text-lg">{children}</h3>
                        ),
                        [BLOCKS.EMBEDDED_ASSET]: node => {
                          if (!node.data.target) {
                            return
                          }
                          // console.log("Imagess",node.data.target.gatsbyImageData)
                          const { gatsbyImageData } = node.data.target
                          if (!gatsbyImageData) {
                            // asset is not an image
                            return null
                          }
                          return (
                            <figure className="flex flex-wrap justify-center items-center">
                              <GatsbyImage
                                image={gatsbyImageData}
                                alt="Blog Image"
                              />
                            </figure>
                          )
                        },
                      },
                    })}
                  </div>

                  <StartButton className="px-12 py-3 bg-coral rounded-full text-sm text-white" />
                </div>
              </OnScreen>
            </div>

            <div className="order-1 text-center lg:order-2">
              <OnScreen classToAdd="slide-in-right">
                <OnScreen
                  className="font-Press-Start my-6 px-4 text-blue text-center text-sm uppercase sm:my-16 sm:text-xl lg:my-24 lg:text-2xl xl:text-3xl"
                  classToAdd="scale-in-ver-center"
                >
                  <p>&quot;I play therefore I am&quot;</p>
                </OnScreen>
                <div className="relative bg-yellow max-w-md mx-auto pr-8 pt-8 red-shadow rounded-md shadow-lg xl:max-w-xl">
                  <StaticImage
                    alt=""
                    className="flip "
                    placeholder="blurred"
                    src="../images/hero.png"
                  />
                  <img
                    srcSet={tedx}
                    // type="image/webp"
                    alt="hero"
                    className="rounded-md absolute"
                    width={"15%"}
                    height="100%"
                    style={{ top: "2%", left: "6%" }}
                  />
                </div>

                <div className="text-center">
                  <p className="border-2 border-coral font-Press-Start inline-block mt-8 mx-auto p-2 text-white md:text-lg">
                    <span className="" style={{ color: "#111c4e" }}>
                      Current Level:
                    </span>{" "}
                    <span className="text-coral">25</span> <br />{" "}
                    <div
                      className="flex flex-wrap justify-center items-center text-core"
                      style={{ color: "#111c4e" }}
                    >
                      and climbing
                      <TypedText
                        className="font-Press-Start mx-auto text-center text-black md:text-lg"
                        text="..."
                      />
                    </div>
                  </p>
                </div>
              </OnScreen>
            </div>
          </div>
        </div>

        <div className="max-w-full mx-auto my-16 px-4 lg:my-32 lg:container">
          <div className="gap-4 grid grid-cols-1 items-center lg:gap-8 lg:grid-cols-2">
            <div className="order-2 mt-16 px-4 lg:order-1 lg:mt-0 lg:px-0">
              <OnScreen classToAdd="slide-in-left">
                <div className="bg-white max-w-xl mx-auto p-4 relative rounded-md shadow-lg">
                  <div className="absolute bg-blue h-24 -left-4 -top-4 w-24 -z-1" />
                  <div className="absolute bg-blue -bottom-4 h-24 -right-4 w-24 -z-1" />

                  <StaticImage
                    alt="Will Moore's Family"
                    placeholder="blurred"
                    src="../images/family.png"
                  />
                </div>
              </OnScreen>
            </div>

            <div className="order-1 lg:order-2">
              <OnScreen classToAdd="slide-in-right">
                <div className="space-y-4">
                  <div className="text-link">
                    {renderRichText(newData.mission, {
                      renderMark: {
                        [MARKS.BOLD]: text => (
                          <span className="font-bold">{text}</span>
                        ),
                        [MARKS.ITALIC]: text => <em>{text}</em>,
                      },
                      renderNode: {
                        [BLOCKS.PARAGRAPH]: (node, children) => (
                          <p>{children}</p>
                        ),
                        [BLOCKS.HEADING_1]: (node, children) => (
                          <h1 className="font-Orbitron text-4xl">{children}</h1>
                        ),
                        [BLOCKS.HEADING_2]: (node, children) => (
                          <h2 className="font-Orbitron text-3xl">{children}</h2>
                        ),
                        [BLOCKS.HEADING_3]: (node, children) => (
                          <h3 className="font-Orbitron text-lg">{children}</h3>
                        ),
                        [BLOCKS.EMBEDDED_ASSET]: node => {
                          if (!node.data.target) {
                            return
                          }
                          // console.log("Imagess",node.data.target.gatsbyImageData)
                          const { gatsbyImageData } = node.data.target
                          if (!gatsbyImageData) {
                            // asset is not an image
                            return null
                          }
                          return (
                            <figure className="flex flex-wrap justify-center items-center">
                              <GatsbyImage
                                image={gatsbyImageData}
                                alt="Blog Image"
                              />
                            </figure>
                          )
                        },
                      },
                    })}
                  </div>
                </div>
              </OnScreen>
            </div>
          </div>
        </div>
      </div>

      <OnScreen className="mb-16 px-4 lg:mb-24" classToAdd="slide-in-left">
        <div className="bg-blue max-w-6xl mx-auto px-4 py-8 rounded-xl shadow-lg text-center text-white">
          <p className="font-Press-Start mb-4 uppercase">
            {newData.quizHeading}
          </p>

          <p>{newData.quiazSubHeading}</p>

          <div className="mt-8">
            <StartButton className="bg-white py-3 px-12 rounded-full text-blue" />
          </div>
        </div>
      </OnScreen>

      <div className="max-w-4xl mb-16 mx-auto lg:mb-24">
        <div className="wistia_responsive_padding relative iframe-wrapper">
          <div className="wistia_responsive_wrapper h-full left-0 absolute top-0 w-full">
            <div className="wistia_embed wistia_async_yrzo75bpk8 videoFoam=true h-full relative w-full">
              <div className="wistia_swatch h-full left-0 opacity-1 overflow-hidden absolute top-0 transition-opacity width-full">
                <img
                  src="https://fast.wistia.com/embed/medias/yrzo75bpk8/swatch"
                  className="filter blur-sm h-full object-contain width-full"
                  alt="Living Your Best Life Video"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="text-center">
          <h2 className="font-Orbitron mb-2 text-2xl md:text-4xl">
            Hear Will's Account In His Own Word
          </h2>
          <h3 className="font-Orbitron mb-4 text-xl md:text-3xl">
            Featured Interviews
          </h3>

          {/* <p>Listen to Will&apos;s first hand account of his epic quest.</p> */}

          <div className="py-4">
            <Swiper
              breakpoints={{
                768: {
                  slidesPerView: 2,
                },
                991: {
                  slidesPerView: 3,
                },
              }}
              modules={[Navigation]}
              navigation={{
                nextEl: ".swiper-next",
                prevEl: ".swiper-prev",
              }}
              slidesPerView={1}
            >
              {interviews.reverse().map(elem => (
                <SwiperSlide className="p-4 swiper-slide-height" key={elem.id}>
                  <OnScreen
                    className="bg-white h-full p-4 rounded-md shadow-lg"
                    classToAdd="scale-in-center"
                  >
                    <a
                      href={elem.link}
                      rel="noreferrer noopener"
                      target="_blank"
                    >
                      <GatsbyImage
                        alt={elem.coverImage.description}
                        className="mb-8"
                        image={elem.coverImage.gatsbyImageData}
                      />

                      <div>
                        <h3 className="font-Orbitron text-center sm:text-lg">
                          {elem.title}
                        </h3>
                      </div>
                    </a>
                  </OnScreen>
                </SwiperSlide>
              ))}

              <div className="my-4 space-x-4">
                <button
                  className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70 swiper-prev"
                  type="button"
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </button>

                <button
                  className="bg-gray border-2 border-black h-12 rounded-full transition-opacity w-12 disabled:opacity-30 hover:opacity-70 swiper-next"
                  type="button"
                >
                  <FontAwesomeIcon icon={faChevronRight} />
                </button>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="max-w-6xl mb-16 mt-8 mx-auto px-4">
        <h2 className="font-Orbitron mb-8 text-center text-3xl lg:text-4xl">
          Testimonials
        </h2>

        <div className="max-w-5xl mx-auto mt-8">
          <div className="space-y-8">{getTestimonials()}</div>
        </div>

        <div className="mt-16 text-center">
          <Button href="/success-stories" link="GATSBY">
            View More
          </Button>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulInterview(
      filter: {
        title: {
          in: [
            "Gamify Your Habits For a Happier Life"
            "The Wealth Matrix - Interview with Will Moore"
            "Short Life Lessons from William Moore"
            "Gamify Your Habits to Level Up"
            "You Are the Champion: Breaking Bad Thought Habits"
          ]
        }
      }
    ) {
      edges {
        node {
          blurb
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          link
          title
        }
      }
    }
    allContentfulSuccessStory(
      filter: { name: { in: ["Harrison Loew", "Mitzi Perdue", "Mark Hansen"] } }
    ) {
      edges {
        node {
          coverImage {
            description
            gatsbyImageData(layout: CONSTRAINED)
          }
          id
          name
          testimonial {
            testimonial
          }
          title
        }
      }
    }
    allContentfulAbout {
      edges {
        node {
          headline
          stepsHeading
          subHeading
          stepOne {
            raw
          }
          stepTwo {
            raw
          }
          stepThree {
            raw
          }
          stepFour {
            raw
          }
          stepFive {
            raw
          }
          stepSix {
            raw
          }
          stepSeven {
            raw
          }

          firstSection {
            raw
          }
          mission {
            raw
            references {
              ... on ContentfulAsset {
                contentful_id
                __typename
                gatsbyImageData
              }
            }
          }
          quizHeading
          quiazSubHeading
        }
      }
    }
  }
`

export default About
